<script lang="ts">
    import { onMount } from "svelte";
    import { _, date } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import CardData from "../../../../components/Elements/CardData.svelte";
    import MetaWrapper from "../../../../components/metadata/MetaWrapper.svelte";
    import PageHeader from "../../../../components/pageHeader/PageHeader.svelte";
    import API from "../../../../services/apiService";
    import { GET_REPAYMENT_PLAN_DETAILS } from "../../../../static/endPoints";
    import { NotificationType } from "../../../../static/types";
    import notificationState from "../../../../store/notifications.store";
    import utils from "../../../../utilsV2/currency";
    import { formatLocaleDateString } from "../../../../utilsV2/helpers";
    import InstalmentsTable from "./InstalmentsTable.svelte";
    import PaymentDetails from "./PaymentDetails.svelte";
    import ProductsTable from "./ProductsTable.svelte";
    import RepaymentPlanStatusToken from "./RepaymentPlanStatusToken.svelte";
    import type { Instalment, RepaymentPlanDetails, RepaymentPlanPeriod } from "./types";

    const INFO_URL =
        "https://twoinc.notion.site/Recourse-What-does-it-mean-14529959c00380b78bb6ccc91344aae3?pvs=4";
    const repaymentPlanId: string = window.location.pathname.split("/").pop();
    // in case we get an unsupported currency, simply return the amount
    // as provided by the BE
    let formatCurrency = (input: string) => input;
    let scrollBox: HTMLDivElement;
    let scrollPos = 0;
    let showInstalments = false;
    let planDetails: RepaymentPlanDetails | undefined;
    let pageTitle = "";
    let loading = false;

    onMount(async () => {
        // TODO prevent call if ID not valid UUID?
        await getPlanDetails(repaymentPlanId);
        pageTitle = $_("planDetails.pageTitle", { values: { id: planDetails?.description } });
    });

    function parseScroll() {
        scrollPos = scrollBox.scrollTop;
    }

    async function getPlanDetails(planId: string) {
        loading = true;
        try {
            planDetails = (await API.get(
                GET_REPAYMENT_PLAN_DETAILS(planId)
            )) as RepaymentPlanDetails;
            showInstalments = planDetails.instalments.length <= 5;
            if (planDetails?.currency) {
                try {
                    formatCurrency = utils.formatCurrency(planDetails.currency);
                } catch (err) {
                    console.log(err);
                }
            }
        } catch (err) {
            // TODO we should have a page to display when something goes wrong
            console.log(err);
            navigate("/merchant/plans");
            var errorMessage = $_("planDetails.errorMessage");
            notificationState.actions.create(NotificationType.ERROR, errorMessage);
        } finally {
            loading = false;
        }
    }

    function getFirstInstalmentInvoiceIssueDate(planDetails: RepaymentPlanDetails): Date | string {
        var firstIssueDate = planDetails.instalments[0]?.invoice_issue_date;
        var result: Date | string = "-";
        if (firstIssueDate) {
            result = formatLocaleDateString(firstIssueDate);
        }
        return result;
    }

    function scrollToInstalments() {
        if (planDetails.instalments.length == 0) return;
        setTimeout(() => {
            showInstalments = true;
            document
                .getElementById("instalments-table")
                ?.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }

    function getLocaleInstalmentPeriod(period: RepaymentPlanPeriod): string {
        return $_(`planDetails.period.${period.toLowerCase()}`);
    }

    function getPageHeaderDescription(onRecourse: boolean): string | undefined {
        if (onRecourse) {
            return $_("planDetails.pageDescription", { values: { url: INFO_URL } });
        }
    }

    function showInstalmentsCard(planDetails: RepaymentPlanDetails): boolean {
        if (planDetails.summary_status == "CANCELLED" && hasZeroInvoices(planDetails.instalments)) {
            return false;
        }
        return planDetails.instalments.length > 0;
    }

    function hasZeroInvoices(instalments: Instalment[]): boolean {
        return instalments.every((inst) => inst.invoice_id == null);
    }
</script>

{#if planDetails != undefined}
    <MetaWrapper title={pageTitle}>
        <PageHeader
            title={pageTitle}
            descriptionHtml={getPageHeaderDescription(planDetails.on_recourse)}
            backLink="/merchant/plans"
            showSummaryInfo={scrollPos === 0}
        >
            <p
                slot="cta"
                class="cursor-pointer link-text hover:text-main"
                on:click={scrollToInstalments}
                on:keypress={scrollToInstalments}
            >
                {#if planDetails.instalments.length}
                    View instalments
                {/if}
            </p>
            <div class="summary-info" slot="summary-info">
                {#if scrollPos === 0}
                    <RepaymentPlanStatusToken status={planDetails.summary_status} />
                    {#if planDetails.on_recourse}
                        <div class="recourse-tag">{$_("planDetails.summaryInfo.onRecourse")}</div>
                    {/if}
                    <CardData label={$_("planDetails.summaryInfo.createdOn")} margin="0"
                        >{$date(new Date(planDetails.created_ts))}</CardData
                    >
                    <CardData label={$_("planDetails.summaryInfo.firstInstalment")} margin="0"
                        >{getFirstInstalmentInvoiceIssueDate(planDetails)}</CardData
                    >
                    <CardData
                        label={$_("planDetails.summaryInfo.numberOfInstalments", {
                            values: {
                                numberOfInstalments: getLocaleInstalmentPeriod(
                                    planDetails.terms.period
                                )
                            }
                        })}
                        margin="0"
                    >
                        {planDetails.terms.number_of_periods}
                    </CardData>
                {/if}
            </div>
        </PageHeader>

        <div class="scroll-box" bind:this={scrollBox} on:scroll={parseScroll}>
            <div class="content">
                <div class="column-view">
                    <div class="plan-details card">
                        <div class="card-header">
                            <p class="card-title">{$_("planDetails.planDetailsCard.title")}</p>
                            <div class="header-data">
                                <CardData label={$_("planDetails.planDetailsCard.businessName")}>
                                    <p class="emphasise">
                                        {planDetails.customer.company_trade_name}
                                    </p>
                                </CardData>
                            </div>
                        </div>
                        <div class="card-content card-columns">
                            <div>
                                <CardData label={$_("planDetails.planDetailsCard.orgNumber")}
                                    >{planDetails.customer.national_identifier}</CardData
                                >
                                <CardData label={$_("planDetails.planDetailsCard.phoneNumber")}
                                    >{planDetails.customer.representative.phone_number}</CardData
                                >
                                <CardData label={$_("planDetails.summaryInfo.firstInstalment")}>
                                    {getFirstInstalmentInvoiceIssueDate(planDetails)}
                                </CardData>
                            </div>
                            <div>
                                <CardData label={$_("planDetails.planDetailsCard.representative")}>
                                    {planDetails.customer.representative.first_name +
                                        " " +
                                        planDetails.customer.representative.last_name}
                                </CardData>
                                <CardData label={$_("planDetails.planDetailsCard.email")}
                                    >{planDetails.customer.representative.email}</CardData
                                >
                                <CardData
                                    label={$_("planDetails.summaryInfo.numberOfInstalments", {
                                        values: {
                                            numberOfInstalments: getLocaleInstalmentPeriod(
                                                planDetails.terms.period
                                            )
                                        }
                                    })}
                                >
                                    {planDetails.terms.number_of_periods}
                                </CardData>
                            </div>
                        </div>
                    </div>
                    <div class="payment-details card">
                        <PaymentDetails {planDetails} {formatCurrency} />
                    </div>
                </div>
                {#if showInstalmentsCard(planDetails)}
                    <div class="card" id="instalments-table">
                        <InstalmentsTable {planDetails} bind:showInstalments />
                    </div>
                {/if}
                <div class="products-on-plan card">
                    <ProductsTable
                        planLineItems={planDetails.plan_line_items}
                        currency={planDetails.currency}
                    />
                </div>
                <div class="column-view">
                    <div class="additional-order-details card card-padding">
                        <p class="card-title">{$_("planDetails.additionalDetails.title")}</p>
                        <div>
                            <div class="consent-date">
                                <CardData label={$_("planDetails.additionalDetails.consentDate")}
                                    >{$date(new Date(planDetails.consent_date))}</CardData
                                >
                            </div>
                            <CardData label={$_("planDetails.additionalDetails.invoiceTerms")}
                                >{$_("planDetails.additionalDetails.days", {
                                    values: { days: planDetails.terms.due_in_days }
                                })}</CardData
                            >
                        </div>
                    </div>
                    <div class="card card-padding">
                        <p class="card-title">{$_("planDetails.planDescription")}</p>
                        <div class="description">{planDetails.description}</div>
                    </div>
                </div>
            </div>
        </div>
    </MetaWrapper>
{/if}

<style lang="scss">
    .content {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .card {
        border-radius: 0.5rem;
        background-color: var(--basic);
        --tw-bg-opacity: 1;
        border: 1px solid #e4e7ec;
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);
        display: flex;
        flex-direction: column;

        .card-header {
            border-bottom: 1px solid var(--gray-200);
            padding: 1.5rem 1.5rem 1rem;
            margin-bottom: 1rem;
        }

        .card-title {
            font-weight: 700;
            margin-bottom: 1rem;
        }

        .card-content {
            padding: 0 1.5rem 1.5rem;
        }

        .card-columns {
            display: flex;
            gap: 30px;

            > div {
                flex-grow: 1;
            }
        }
    }

    .card-padding {
        padding: 1.5rem;
    }

    .column-view {
        display: flex;
        gap: 16px;

        > div {
            flex-grow: 1;
        }
    }

    .description {
        border: 1px solid var(--gray-100);
        border-radius: 8px;
        color: var(--gray-600);
        background-color: var(--gray-50);
        padding: 10px 14px;
        flex-grow: 1;
    }

    .scroll-box {
        overflow: auto;
        height: calc(100vh - 85px);
    }

    .emphasise {
        font-size: 1rem;
        font-weight: 700;
    }

    .consent-date {
        margin-bottom: 1.5rem;
    }

    .recourse-tag {
        background-color: var(--gray-700);
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding: 4px 8px;
    }

    .summary-info {
        display: flex;
        gap: 1rem;
    }

    .link-text {
        color: var(--ctnInteractiveSemanticHighlightDefault);
        font-weight: 500;
        font-size: 0.875rem;
        padding-inline: 1rem;
    }

    .plan-details,
    .payment-details {
        max-width: 50%;
    }

    .header-data {
        display: flex;
    }
</style>
