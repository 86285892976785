<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faXmark } from "@fortawesome/free-solid-svg-icons";
    import { Button } from "@two-ui/core";
    import { onMount } from "svelte";

    import CheckboxItem from "@/components/checkboxitem/CheckboxItem.svelte";
    import FiltersSidebar from "@/components/filters/FiltersSidebar.svelte";
    import {
        actions as customerActions,
        buyerRecourseLimit,
        buyerRecourseLimitMaxAllowed,
        buyerRecourseLimitLoading,
        buyerRecourseLimitMaxAllowedLoading
    } from "@/store/merchant/customers.store";
    import modalState from "@/store/modals.store";
    import { account, signupComplete, recourseEnabled } from "@/store/merchant/account.store";
    import Input from "@/components/Elements/InputBox.svelte";
    import Switch from "@/components/Elements/Switch.svelte";
    import type { ICustomer, ICustomerRecourseLimit, CurrencyCode } from "@/static/types";
    import { titleCase } from "@/utilsV2/helpers";
    import { getCurrency } from "@/utils/functions";
    import {
        validateCustomerRecourseAmount,
        validateCustomerRecourseAmountWithMaxAllowed
    } from "@/utils/validation";
    import SignupIncompleteModal from "@/components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import environment from "@/services/environment";

    export let selectedCustomer: ICustomer;
    export let toggleMoreVolumeSidebar;
    export let isRecourseEnabledForBuyer;

    let buyerRecourseLimitAlreadySet: boolean = true;
    let buyerRecourseLimitDisplay: ICustomerRecourseLimit;
    let updatedBuyerRecourseLimitAmount: string;
    let recourseLimitError: string = "";
    let invalidRecourseAmountError: string = "";

    let isActiveRecourseInputField: boolean = false;

    let recourseFallbackEnabled: boolean = false;

    // Guaranteed checkout related states
    let guaranteedCheckoutEnabled: boolean = selectedCustomer?.direct_invoice_fallback;
    let guaranteedCheckoutLimit: string = selectedCustomer?.fallback_threshold;

    let buyerFallbackEnabledOnMerchant = false;
    let merchantCurrencyCode = "GBP";
    let customerCurrencyCode: CurrencyCode = "GBP";

    let isValidateCustomerRecourseLimit = false;
    let isIAmLiableToPayChecked = false;
    let iAmLiableError = "";
    let isLoading = true;
    let isSwitchedToDisableRecourse = false;

    $: {
        isLoading = $buyerRecourseLimitLoading || $buyerRecourseLimitMaxAllowedLoading;
    }

    $: {
        isSwitchedToDisableRecourse =
            !recourseFallbackEnabled && !isLoading && isRecourseEnabledForBuyer;
    }

    $: {
        buyerFallbackEnabledOnMerchant = $account?.flags?.buyer_fallback_enabled;
        merchantCurrencyCode = $account?.feeCurrency;
        isValidateCustomerRecourseLimit =
            $account?.flags?.enable_fe_validate_customer_recourse_limit;
    }

    onMount(() => {
        if ($recourseEnabled) {
            // feature flag enable_fe_validate_customer_recourse_limit check
            if (isValidateCustomerRecourseLimit) {
                customerActions.getCustomerMaxAllowedRecourseLimit(
                    selectedCustomer?.buyer_organization_number,
                    selectedCustomer?.buyer_country_code
                );
            }
        }
    });

    $: customerCurrencyCode =
        $buyerRecourseLimit?.currency || getCurrency(selectedCustomer?.buyer_country_code);

    function handleClickPostAction(action, validationRequired) {
        if ($signupComplete) {
            return action(validationRequired);
        }
        modalState.actions.setModal("", {}, SignupIncompleteModal);
    }

    // eslint-disable-next-line
    function validateCustomerSaveSettings(validationRequired: boolean) {
        let valid = true;
        if (guaranteedCheckoutLimit !== null) {
            guaranteedCheckoutLimit = guaranteedCheckoutLimit.replace(/,/g, "");
        }
        if (guaranteedCheckoutEnabled && buyerFallbackEnabledOnMerchant) {
            if (isNaN(parseInt(guaranteedCheckoutLimit))) {
                valid = false;
            }
        }

        if (validationRequired) {
            // limit amount
            if (!+updatedBuyerRecourseLimitAmount) {
                recourseLimitError = $_("validation.inputAmount");
                valid = false;
            } else {
                recourseLimitError = "";
            }
            // checkbox
            if (!isIAmLiableToPayChecked) {
                iAmLiableError = $_("customers.combined.settings.financialLiableError");
                valid = false;
            } else {
                iAmLiableError = "";
            }
        }

        if (valid) {
            // check if feature flag is disabled or if enabled then max allowed limit is required
            if (
                $recourseEnabled &&
                (!isValidateCustomerRecourseLimit ||
                    (isValidateCustomerRecourseLimit && $buyerRecourseLimitMaxAllowed))
            ) {
                const data: ICustomerRecourseLimit = {
                    buyer_organization_number: selectedCustomer?.buyer_organization_number,
                    buyer_country_code: selectedCustomer?.buyer_country_code,
                    max_amount: recourseFallbackEnabled ? updatedBuyerRecourseLimitAmount : "0",
                    currency:
                        $buyerRecourseLimit?.currency ||
                        getCurrency(selectedCustomer?.buyer_country_code)
                };

                // check if merchant is enabling the recourse fallback for this customer first time
                customerActions.updateCustomerRecouseLimit(
                    data,
                    selectedCustomer?.buyer_company_name,
                    buyerRecourseLimitAlreadySet // if customer recourse limit is already being added on BE table
                );
            }
            // close sidebar
            toggleMoreVolumeSidebar();
        }
    }

    $: if (
        $buyerRecourseLimit?.error_code &&
        $buyerRecourseLimit?.error_code == "MERCHANT_BUYER_RECOURSE_LIMIT_NOT_FOUND_ERROR"
    ) {
        buyerRecourseLimitAlreadySet = false;
        buyerRecourseLimitDisplay = undefined;
        updatedBuyerRecourseLimitAmount = "0";
    } else {
        if ($buyerRecourseLimit?.merchant_id) {
            buyerRecourseLimitAlreadySet = true;
            buyerRecourseLimitDisplay = $buyerRecourseLimit;
            updatedBuyerRecourseLimitAmount = $buyerRecourseLimit?.merchant_buyer_recourse_limit;
        }
    }

    $: recourseFallbackEnabled = parseFloat(updatedBuyerRecourseLimitAmount) > 0 ? true : false;

    $: invalidRecourseAmountError =
        isValidateCustomerRecourseLimit && $buyerRecourseLimitMaxAllowed
            ? validateCustomerRecourseAmountWithMaxAllowed(
                  updatedBuyerRecourseLimitAmount,
                  $buyerRecourseLimitMaxAllowed,
                  customerCurrencyCode,
                  $_
              )
            : validateCustomerRecourseAmount(
                  updatedBuyerRecourseLimitAmount,
                  customerCurrencyCode,
                  $_
              );
</script>

<FiltersSidebar>
    <div slot="header" class="filter-header">
        <div>
            <span
                >{isRecourseEnabledForBuyer
                    ? $_("customer.creditLimit.editRecourseFallback")
                    : $_("customer.creditLimit.unlockMoreVolume")}</span
            >
            {#if isLoading}
                <sup class="text-xs">{$_("components.loading")}</sup>
            {/if}
        </div>
        <div class="close pt-1">
            <button on:click={toggleMoreVolumeSidebar}>
                <Fa icon={faXmark} size="md" color="var(--gray-500)" class="cursor-pointer" />
            </button>
        </div>
    </div>
    <div slot="main">
        <!-- Check if merchant recourse fallback is enabled on BE -->
        <div class="filter-item border-b-0 mb-0">
            <div class="filter-item-header-text">
                {isSwitchedToDisableRecourse
                    ? $_("customers.combined.settings.sureToDisableRecourse")
                    : $_("customers.combined.settings.recourseFallback")}
                <div class="float-right">
                    <span class="font-normal text-sm mr-1">
                        {recourseFallbackEnabled
                            ? $_("customers.combined.settings.enabled")
                            : $_("customers.combined.settings.disabled")}
                    </span>
                    <Switch
                        checked={recourseFallbackEnabled}
                        on:change={(e) => {
                            recourseFallbackEnabled = e.detail.checked;
                            isActiveRecourseInputField =
                                e.detail.checked &&
                                !(parseFloat(updatedBuyerRecourseLimitAmount) > 0);
                        }}
                    />
                </div>
            </div>
            <span>
                {#if isSwitchedToDisableRecourse}
                    {$_("customers.combined.settings.disableRecourseText", {
                        values: {
                            brandName: environment.branding.displayName
                        }
                    })}
                {:else}
                    {@html $_("customers.combined.settings.moreVolumeText", {
                        values: {
                            customerName: titleCase([selectedCustomer?.buyer_company_name])[0],
                            brandName: environment.branding.displayName
                        }
                    })}
                {/if}
            </span>
        </div>

        {#if !isSwitchedToDisableRecourse}
            <div class="filter-item border-b-0">
                <label for="buyer-recourse-amount-volume" class="text-sm font-medium mb-[-10px]"
                    >{$_("customers.combined.settings.limitLabel", {
                        values: {
                            customerName: titleCase([selectedCustomer?.buyer_company_name])[0]
                        }
                    })}</label
                >
                <div class="relative with-type" class:mb-4={recourseLimitError}>
                    <Input
                        id="buyer-recourse-amount-volume"
                        type="text"
                        invalid={recourseLimitError
                            ? `
                                ${recourseLimitError || ""}
                                ${$_("customers.combined.settings.maxRecourseLimit", {
                                    values: {
                                        customerName: titleCase([
                                            selectedCustomer?.buyer_company_name
                                        ])[0]
                                    }
                                })}
                                ${$buyerRecourseLimitMaxAllowed || 0}
                                ${customerCurrencyCode}
                            `
                            : ""}
                        value={isLoading
                            ? $_("components.loading")
                            : buyerRecourseLimitDisplay?.merchant_buyer_recourse_limit}
                        placeholder="20,000.00"
                        on:change={(e) => {
                            updatedBuyerRecourseLimitAmount = e.detail.value;
                            if (parseFloat(updatedBuyerRecourseLimitAmount) > 0) {
                                isActiveRecourseInputField = false;
                            }
                        }}
                    />
                    <span class="item-type">{customerCurrencyCode}</span>
                </div>

                {#if invalidRecourseAmountError.length && !recourseLimitError}
                    <p class="text-sm text-red-500">{invalidRecourseAmountError}</p>
                {:else if !recourseLimitError}
                    <p class="text-sm text-gray-500">
                        {$_("customers.combined.settings.maxRecourseLimit", {
                            values: {
                                customerName: titleCase([selectedCustomer?.buyer_company_name])[0]
                            }
                        })}
                        {$buyerRecourseLimitMaxAllowed || 0}
                        {customerCurrencyCode}
                    </p>
                {/if}

                <div class="mt-4 flex items-baseline font-normal cursor-pointer">
                    <CheckboxItem
                        statusItem={{ label: "", value: "" }}
                        isChecked={isIAmLiableToPayChecked}
                        isInvalid={Boolean(iAmLiableError)}
                        on:checkedItem={() => (isIAmLiableToPayChecked = !isIAmLiableToPayChecked)}
                    />
                    <button
                        id="i-am-eligible-checkbox"
                        on:click={() => (isIAmLiableToPayChecked = !isIAmLiableToPayChecked)}
                        class="ml-2 text-left"
                    >
                        {$_("customers.combined.settings.financialLiable", {
                            values: {
                                customerName: titleCase([selectedCustomer?.buyer_company_name])[0],
                                brandName: environment.branding.displayName
                            }
                        })}
                    </button>
                </div>
                {#if iAmLiableError}
                    <p class="text-sm text-red-500">{iAmLiableError}</p>
                {/if}
            </div>
        {/if}
    </div>
    <div slot="footer" class="filter-footer">
        {#if isSwitchedToDisableRecourse}
            <Button
                size="lg"
                variant="primary"
                disabled={isLoading}
                content={$_("components.disable")}
                on:click={() => handleClickPostAction(validateCustomerSaveSettings, false)}
            />
        {:else}
            <Button
                size="lg"
                variant="primary"
                id={isRecourseEnabledForBuyer
                    ? "save-recourse-settings"
                    : "enable-recourse-settings"}
                content={isRecourseEnabledForBuyer
                    ? $_("customers.combined.settings.saveSettings")
                    : $_("customer.creditLimit.enable")}
                on:click={() => handleClickPostAction(validateCustomerSaveSettings, true)}
            />
        {/if}
        <Button
            size="lg"
            on:click={toggleMoreVolumeSidebar}
            variant="secondaryGray"
            content={$_("components.cancel")}
        />
    </div>
</FiltersSidebar>
