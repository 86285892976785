<script lang="ts">
    import { type FraudListItem } from "./fraudDataAdaptor";
    import utils from "@/utilsV2/currency";

    export let insight: FraudListItem;
    export let selectedInsightId: string;
    export let onClick;
</script>

<div
    class="fraud-card flex flex-col bg-white px-6 py-4 border-b w-full
    transition-colors duration-300 ease-in-out cursor-pointer"
    class:selected={insight.uuid === selectedInsightId}
    on:click={() => onClick(insight.uuid)}
    on:keyup
    tabindex="0"
    role="button"
>
    <!-- Top Bar -->
    <div class="flex justify-between items-center">
        <span class={`status-${insight.status} text-xs rounded-full px-2 py-0.5`}>
            {insight.status}
        </span>
        <span class="text-xs text-content-general-tertiary">{insight.dateTime}</span>
    </div>

    <!-- Content -->
    <div class="flex justify-between items-center mt-2">
        <div>
            <h2 class="text-sm font-medium leading-tight">
                {insight.companyName}
            </h2>
            <p class="company-id text-sm mt-1">{insight.companyId}</p>
        </div>

        <!-- Amount and Currency -->
        {#if insight.currency && insight.amount}
            <div class="flex justify-end mt-4">
                <span class="text-sm">
                    {utils.formatCurrency(insight.currency)(insight.amount)}
                </span>
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
    .status-Order {
        color: var(--ctnSemanticInfoPrimary);
        background-color: var(--bgSemanticInfoUltraLight);
    }
    .status-Onboarding {
        color: var(--ctnSemanticWarningPrimary);
        background-color: var(--bgSemanticWarningTertiary);
    }
    .company-id {
        color: var(--ctnGeneralSecondary);
    }
    .fraud-card {
        &:hover,
        &.selected {
            background-color: var(--bgBrandPrimary);
        }
    }
</style>
