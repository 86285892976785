<!-- This component is similar to CombinedCustomersSettings.svelte -->
<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import Switch from "../../../../components/Elements/Switch.svelte";
    import FiltersSidebar from "../../../../components/filters/FiltersSidebar.svelte";
    import Selector from "../../../../components/Elements/Selector.svelte";
    import InputBox from "../../../../components/Elements/InputBox.svelte";
    import {
        buyerRecourseLimit,
        buyerRecourseLimitMaxAllowed,
        buyerRecourseLimitLoading,
        buyerRecourseLimitMaxAllowedLoading
    } from "@/store/merchant/customers.store";
    import modalState from "../../../../store/modals.store";
    import { account, signupComplete } from "../../../../store/merchant/account.store";
    import { actions as insightsActions } from "@/store/merchant/creditInsights.store";
    import ToolTip from "../../../../components/tooltip/ToolTip.svelte";
    import type { ICustomer, CurrencyCode } from "../../../../static/types";
    import Icon from "../../../../components/icons/Icon.svelte";
    import utils, { type CurrencyFormatOptions } from "../../../../utilsV2/currency";
    import { titleCase } from "../../../../utilsV2/helpers";
    import { getCurrency } from "../../../../utils/functions";
    import {
        validateCustomerRecourseAmount,
        validateCustomerRecourseAmountWithMaxAllowed
    } from "../../../../utils/validation";
    import SignupIncompleteModal from "../../../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import environment from "@/services/environment";

    export let selectedCustomer: ICustomer;

    const PAYMENT_TERMS_TOOLTIP_TEXT = $_("customers.combined.settings.paymentTermsTooltip");

    let updatedBuyerRecourseLimitAmount: string;
    let invalidRecourseAmountError: string = "";

    let paymentTerm: string = (selectedCustomer?.due_in_days || 30) + " days";
    const paymentTermsOptions = ["14 days", "21 days", "30 days", "45 days", "60 days", "90 days"];

    let customerCurrencyCode: CurrencyCode = "GBP";

    let isValidateCustomerRecourseLimit = false;
    let showMore = false;
    let overwriteCreditLimit = false;
    const overwriteCreditLimitAmount = 45000;

    $: {
        isValidateCustomerRecourseLimit =
            $account?.flags?.enable_fe_validate_customer_recourse_limit;
    }

    $: customerCurrencyCode =
        $buyerRecourseLimit?.currency || getCurrency(selectedCustomer?.buyer_country_code);

    function handleClickPostAction(action) {
        if ($signupComplete) {
            return action();
        }
        modalState.actions.setModal("", {}, SignupIncompleteModal);
    }

    function validateCustomerSaveSettings() {
        console.log("validateCustomerSaveSettings");
    }

    const formatCustomerMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(customerCurrencyCode)(money, options);

    function closeSettingsModal() {
        insightsActions.toggleSettings(false);
    }

    $: if (
        $buyerRecourseLimit?.error_code &&
        $buyerRecourseLimit?.error_code == "MERCHANT_BUYER_RECOURSE_LIMIT_NOT_FOUND_ERROR"
    ) {
        updatedBuyerRecourseLimitAmount = "0";
    } else {
        if ($buyerRecourseLimit?.merchant_id) {
            updatedBuyerRecourseLimitAmount = $buyerRecourseLimit?.merchant_buyer_recourse_limit;
        }
    }

    $: invalidRecourseAmountError =
        isValidateCustomerRecourseLimit && $buyerRecourseLimitMaxAllowed
            ? validateCustomerRecourseAmountWithMaxAllowed(
                  updatedBuyerRecourseLimitAmount,
                  $buyerRecourseLimitMaxAllowed,
                  customerCurrencyCode,
                  $_
              )
            : validateCustomerRecourseAmount(
                  updatedBuyerRecourseLimitAmount,
                  customerCurrencyCode,
                  $_
              );
</script>

<FiltersSidebar>
    <div slot="header" class="filter-header">
        <div>
            <span>{$_("customers.combined.settings.customerSettings")}</span>
            {#if $buyerRecourseLimitLoading || $buyerRecourseLimitMaxAllowedLoading}
                <sup class="text-xs">{$_("components.loading")}</sup>
            {/if}
        </div>
        <div class="close pt-1">
            <button on:click={closeSettingsModal}>
                <Icon name="cross" fill={"#98A2B3"} size={24} />
            </button>
        </div>
    </div>
    <div slot="main">
        <div class="filter-item">
            <span class="filter-item-header-text"
                >{$_("customers.combined.settings.customerDetails")}</span
            >
            <div class="flex-details">
                <div class="flex">
                    <div>
                        <p class="text-sm ctn-general-tertiary">
                            {$_("customers.combined.settings.businessName")}
                        </p>
                        <h3 class="text-lg ctn-general-primary font-bold">
                            {titleCase([selectedCustomer?.buyer_company_name])[0]}
                        </h3>
                    </div>
                    <div>
                        <p class="text-sm text-right ctn-general-secondary">
                            {$_("customers.combined.settings.orgNumber")}: {selectedCustomer?.buyer_organization_number}
                        </p>
                    </div>
                </div>
                <hr class="mt-2 mb-2" />
                {#if overwriteCreditLimitAmount}
                    <div class="flex">
                        <div>{$_("customers.insights.overwriteCreditLimit")}</div>
                        <div>
                            <p class="text-right font-bold">
                                {formatCustomerMoney(overwriteCreditLimitAmount || 0)}
                            </p>
                        </div>
                    </div>
                    <div class="flex ctn-interactive-general-disabled">
                        <div>
                            {$_("customers.insights.brandsCreditLimit", {
                                values: { brandName: environment.branding.displayName }
                            })}
                        </div>
                        <div>
                            <p class="text-right font-bold">
                                {formatCustomerMoney(overwriteCreditLimitAmount - 450 || 0)}
                            </p>
                        </div>
                    </div>
                {:else}
                    <div class="flex">
                        <div>{$_("customers.combined.settings.currentCreditLimit")}</div>
                        <div>
                            <p class="text-right font-bold">
                                {formatCustomerMoney(selectedCustomer?.credit_limit || 0)}
                            </p>
                        </div>
                    </div>
                {/if}

                <div class="flex">
                    <div>{$_("customers.combined.settings.remainingCreditLimit")}</div>
                    <div>
                        <p class="text-right font-bold">
                            {formatCustomerMoney(selectedCustomer?.credit_limit_balance || 0)}
                        </p>
                    </div>
                </div>
                <div class="flex">
                    <div>{$_("customers.insights.currentPaymentTerms")}</div>
                    <div>
                        <p class="text-right font-bold">
                            {paymentTerm}
                        </p>
                    </div>
                </div>

                {#if showMore}
                    <div class="flex mt-8">
                        <div>
                            <h3 class="text-lg ctn-general-primary font-bold">
                                {$_("customers.insights.volumeOutstanding")}
                            </h3>
                            <p class="text-sm">
                                {$_("customers.insights.withPartnerOnly", {
                                    values: { partner: "Komplett" }
                                })}
                            </p>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="flex">
                        <div>{$_("customers.insights.unpaidInvoices")}</div>
                        <div>
                            <p class="text-right font-bold">
                                {formatCustomerMoney(selectedCustomer?.credit_limit || 0)}
                            </p>
                        </div>
                    </div>
                    <div class="flex mb-4">
                        <div class="as-sub-item">{$_("statuses.overdue")}</div>
                        <div>
                            <p class="text-right font-bold">
                                {formatCustomerMoney(selectedCustomer?.credit_limit || 0)}
                            </p>
                        </div>
                    </div>
                {/if}

                <p class="text-left">
                    <button on:click={() => (showMore = !showMore)} class="view-more-btn mt-2">
                        {showMore ? $_("components.viewLess") : $_("components.viewMore")}
                    </button>
                </p>
            </div>
        </div>

        <div class="filter-item">
            <div class="filter-item-header-text">
                {$_("customers.combined.settings.updatePaymentTerms")}
                <div class="tooltip-container">
                    <ToolTip width="250px" position="top" content={PAYMENT_TERMS_TOOLTIP_TEXT}>
                        <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
                    </ToolTip>
                </div>
            </div>
            <span>{$_("customers.combined.settings.paymentTerms")}</span>
            <div class="dueInDaysSelector">
                <Selector
                    value={paymentTerm}
                    showArrow={true}
                    on:change={(e) => {
                        paymentTerm = e.detail.value;
                    }}
                    options={paymentTermsOptions}
                    backgroundColor="white"
                />
            </div>
        </div>
        <div class="filter-item">
            <div class="filter-item-header-text">
                {$_("customers.insights.overwriteCreditLimit")}
                <div class="tooltip-container">
                    <ToolTip
                        width="250px"
                        position="top"
                        content={$_("customers.insights.overwriteCreditLimitTooltip", {
                            values: { merchantCompany: "MerchantName" }
                        })}
                    >
                        <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
                    </ToolTip>
                </div>
                <div class="float-right">
                    <span class="font-normal text-sm mr-1">
                        {overwriteCreditLimit
                            ? $_("customers.combined.settings.enabled")
                            : $_("customers.combined.settings.disabled")}
                    </span>
                    <Switch
                        checked={overwriteCreditLimit}
                        on:change={(e) => {
                            overwriteCreditLimit = e.detail.checked;
                        }}
                    />
                </div>
            </div>
            <span>
                {$_("customers.insights.overwriteCreditLimitDescription", {
                    values: {
                        customer: selectedCustomer?.buyer_company_name,
                        brandName: environment.branding.displayName
                    }
                })}
            </span>
        </div>
        {#if overwriteCreditLimit}
            <div class="filter-item">
                <div>
                    {$_("customers.insights.overwriteLimitInputLabel")}
                    <div class="tooltip-container">
                        <ToolTip
                            width="250px"
                            position="top"
                            content={$_("customers.insights.overwriteLimitInputTooltip")}
                        >
                            <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
                        </ToolTip>
                    </div>
                </div>

                <div class="relative with-type">
                    <InputBox
                        id="overwrite-credit-limit"
                        type="text"
                        placeholder={formatCustomerMoney(overwriteCreditLimitAmount || "20,000.00")}
                    />
                    <span class="item-type">{customerCurrencyCode}</span>
                </div>

                {#if invalidRecourseAmountError.length}
                    <p class="text-sm text-red-500">{invalidRecourseAmountError}</p>
                {:else}
                    <p class="text-sm text-gray-500">
                        {$_("customers.insights.overwriteLimitInputDescription")}
                    </p>
                {/if}
            </div>
        {/if}
    </div>
    <div slot="footer" class="filter-footer">
        <div class="flex gap-2">
            <TwoUIButton
                on:click={() => handleClickPostAction(validateCustomerSaveSettings)}
                size="md"
                variant="primary"
                content={$_("customers.combined.settings.saveSettings")}
                disabled={Boolean(invalidRecourseAmountError.length) ||
                    $buyerRecourseLimitLoading ||
                    $buyerRecourseLimitMaxAllowedLoading}
            />
            <TwoUIButton
                on:click={closeSettingsModal}
                size="md"
                variant="secondaryGray"
                content={$_("components.cancel")}
            />
        </div>
    </div>
</FiltersSidebar>

<style>
    .filter-header {
        display: flex;
        justify-content: space-between;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        color: var(--ctnGeneralPrimary);
    }

    .filter-footer {
        display: flex;
        gap: 0.5rem;
    }

    .filter-item-header-text {
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: var(--ctnGeneralPrimary);
    }
    .filter-item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 5px 0 30px 0;
        margin: 0 0 1rem 0;
        gap: 0.8rem;
    }

    .flex-details {
        padding: 15px;
        border-radius: 10px;
        background-color: #f9fafb;
        display: flex;
        flex-direction: column;
    }

    .flex-details > .flex {
        flex-direction: row;
        align-items: flex-end;
        padding: 5px 0 0 0;
    }

    .flex-details > .flex > div {
        flex: auto;
    }

    .tooltip-container {
        display: inline-block;
        margin: auto 0.5rem;
        font-size: 14px;
    }
    .view-more-btn {
        color: var(--primary-600);
    }
    .as-sub-item {
        &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 14px;
            border-left: 1px solid;
            border-bottom: 1px solid;
            position: relative;
            top: -4px;
            left: 5px;
            margin-right: 10px;
        }
    }
</style>
