import environment from "@/services/environment";
import { monthNames } from "@/static/monthName";

export type ChartSettings = {
    id: string;
    type?: string;
    lineData: Array<unknown>;
    label: Array<unknown>;
    hoverStrings?: [string, string] | [string];
    yAxisTitle: string;
    colours: [string, string] | [string];
    yTickModifier?: (value: number) => string;
};

export const getExposureChangeOverTimeChart = (
    firstLabel: string,
    secondLabel: string,
    exposureData: any[]
): ChartSettings => {
    const lineData: number[][] = [[], []];
    const labels: string[] = [];

    exposureData.sort(
        (a, b) => new Date(a.week_end_date).getTime() - new Date(b.week_end_date).getTime()
    );

    const exposureMap: { [key: string]: { OVERRIDE?: number; ORGANIC?: number } } = {};
    exposureData.forEach((data) => {
        if (!exposureMap[data.week_end_date]) {
            exposureMap[data.week_end_date] = {};
        }
        exposureMap[data.week_end_date][data.exposure_type] = data.weekly_exposure;
    });

    Object.keys(exposureMap).forEach((date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric"
        });
        labels.push(formattedDate);
        lineData[0].push(exposureMap[date].OVERRIDE || 0);
        lineData[1].push(exposureMap[date].ORGANIC || 0);
    });

    return {
        id: "exposure-change-over-time",
        lineData,
        label: labels,
        hoverStrings: [firstLabel, secondLabel],
        yAxisTitle: "",
        colours: ["#DC6803", environment.branding.chartMainColor]
    };
};

export const getLossesPerMonthChart = (
    id: string,
    firstLabel: string,
    secondLabel: string,
    creditLosses: any[]
): ChartSettings => {
    const lineData: number[][] = [[], []];
    const labels: string[] = [];

    creditLosses.sort((a, b) => a.month - b.month);

    const lossesMap: { [key: number]: { OVERRIDE?: number; ORGANIC?: number } } = {};

    creditLosses.forEach((data) => {
        if (!lossesMap[data.month]) {
            lossesMap[data.month] = {};
        }
        lossesMap[data.month][data.exposure_type] = data.monthly_losses;
    });

    Object.keys(lossesMap).forEach((month) => {
        labels.push(monthNames[parseInt(month) - 1]);
        lineData[0].push(lossesMap[month].OVERRIDE || 0);
        lineData[1].push(lossesMap[month].ORGANIC || 0);
    });

    return {
        id,
        lineData,
        label: labels,
        hoverStrings: [firstLabel, secondLabel],
        yAxisTitle: "",
        colours: [environment.branding.chartRiskAltColor, environment.branding.chartMainColor]
    };
};

export const getLostPercentageVolumeChart = (
    id: string,
    firstLabel: string,
    secondLabel: string,
    creditLosses: any[]
): ChartSettings => {
    const lineData: number[][] = [[], []];
    const labels: string[] = [];

    if (creditLosses.length === 1) {
        const data = creditLosses[0];
        labels.push(monthNames[data.month - 1]);
        if (data.exposure_type === "OVERRIDE") {
            lineData[0].push(data.monthly_loss_rate * 100);
            lineData[1].push(0);
        } else if (data.exposure_type === "ORGANIC") {
            lineData[0].push(0);
            lineData[1].push(data.monthly_loss_rate * 100);
        }
    } else {
        creditLosses.sort((a, b) => a.month - b.month);

        const lossRateMap: { [key: number]: { OVERRIDE?: number; ORGANIC?: number } } = {};

        creditLosses.forEach((data) => {
            if (!lossRateMap[data.month]) {
                lossRateMap[data.month] = {};
            }
            lossRateMap[data.month][data.exposure_type] = data.monthly_loss_rate * 100;
        });

        Object.keys(lossRateMap).forEach((month) => {
            labels.push(monthNames[parseInt(month) - 1]);
            lineData[0].push(lossRateMap[month].OVERRIDE || 0);
            lineData[1].push(lossRateMap[month].ORGANIC || 0);
        });
    }

    return {
        id,
        type: "line",
        lineData,
        label: labels,
        hoverStrings: [firstLabel, secondLabel],
        yAxisTitle: "",
        colours: ["#DC6803", environment.branding.chartMainColor]
    };
};
