import moment from "moment";
import { capitalize } from "lodash";

import type { Address, BillingAccount, TradeAccount } from "@/static/types";

export type TradeAccountUI = {
    id: string;
    merchantId: string;
    name: string;
    legalName: string;
    createdAt: string;
    shippingAddress: string;
    verificationType: string;
    verificationTime: string | null;
};

export type BillingAccountUI = {
    id: string;
    name: string;
    accountantEmails: string[];
    ccEmails: string[];
    phone: string;
    currency: string;
    billingPeriod: string;
    billingPeriodDetails: string;
    paymentTerms: string;
    statementDueDate: string;
    lastOrderDate: string;
};

const DATE_FORMAT = "DD/MM/YYYY";

export const adaptTradeAccountsToUI = (accounts: Array<TradeAccount>): Array<TradeAccountUI> => {
    const getAddressString = (address: Address) => {
        if (!address) return "-";
        return [address.street_address, address.city, address.postal_code, address.country]
            .filter((i) => i)
            .join(", ");
    };

    return accounts.map((account) => {
        const verification = account.verifications.find((verification) => verification.verified);
        return {
            id: account.id,
            merchantId: account.merchant_id,
            name: account.trade_name || "",
            legalName: account.legal_name,
            createdAt: moment(account.date_created).format(DATE_FORMAT),
            shippingAddress: getAddressString(account.shipping_addresses[0]),
            verificationType: verification ? verification.verification_type : "Email",
            verificationTime: verification ? verification.verified_on : null
        };
    });
};

export const adaptTradingAccountToBackend = (data: Partial<TradeAccountUI>) => {
    const { legalName, name, ...rest } = data;
    return { legal_name: legalName, trade_name: name, ...rest };
};

export const adaptBillingAccountToBackend = (data: Partial<BillingAccountUI>) => {
    const { accountantEmails, ccEmails, name, phone, paymentTerms } = data;
    const result = {
        primary_email_addresses: accountantEmails,
        cc_email_addresses: ccEmails
    };
    if (name) result.account_name = name;
    if (phone) result.phone_number = phone;
    if (paymentTerms) result.due_in_days = +paymentTerms;
    return result;
};

export const adaptBillingAccountsToUI = (
    accounts: Array<BillingAccount>,
    $_
): Array<BillingAccountUI> => {
    return accounts.map((account) => ({
        id: account.id,
        name: account.account_name,
        accountantEmails: account.primary_email_addresses,
        ccEmails: account.cc_email_addresses || [],
        phone: account.phone_number || "",
        currency: account.currency,
        billingPeriod: capitalize(account.schedule),
        billingPeriodDetails:
            account.last_completed_period_start_date && account.last_completed_period_end_date
                ? `${$_("account.lastCycle") as string}: ${moment(
                      account.last_completed_period_start_date
                  ).format(DATE_FORMAT)} - ${moment(account.last_completed_period_end_date).format(
                      DATE_FORMAT
                  )}`
                : "",
        paymentTerms: String(account.due_in_days),
        statementDueDate: "",
        // account.due_in_days && account.due_in_days > 0
        //     ? `${$_("account.statementDueDate") as string}: ${moment()
        //           .add(account.due_in_days, "days")
        //           .format(DATE_FORMAT)}`
        //     : "",
        lastOrderDate: account.last_statement_issue_date
            ? moment(account.last_statement_issue_date).format(DATE_FORMAT)
            : "-"
    }));
};

export const formatDate = (dateString: string, showTime = true) => {
    const date = new Date(dateString);

    let result = date.toLocaleDateString("en-GB");
    if (showTime) {
        result +=
            " " +
            date.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
    }

    return result;
};
