<script lang="ts">
    import { onDestroy } from "svelte";
    import modalState from "../../store/modals.store";
    // Modal Status
    import {
        EMAIL_SENT,
        DELETE_USER,
        ADD_CUSTOMER_MODAL,
        SEND_BUSINESS_REGISTRATION_INVITE_MODAL,
        EDIT_INVOICE,
        FULFILL_ORDER,
        REFUND_ORDER,
        CANCEL_ORDER,
        EDIT_CREDITLIMIT,
        CONFIRM_EDIT_CREDIT,
        WELCOME_TILLIT,
        ACCOUNT_SETUP,
        SANDBOX_API_KEY,
        API_KEY,
        CREATE_UPDATE_USER_MERCHANT,
        DELETE_USER_MERCHANT,
        CREATE_USER_MERCHANT_SUCCESS,
        UPDATE_USER_MERCHANT_SUCCESS,
        DELETE_USER_MERCHANT_SUCCESS,
        CONFIRM_LOGOUT,
        EDIT_CUSTOMER,
        CREATE_ORDER,
        CROSS_BORDER_ORDER,
        MI_BUYER_FALLBACK,
        CONFIRM_ENABLE_CUSTOMER_RECOURSE_FALLBACK,
        CANCEL_REMAINING_ORDER,
        FRAUD_RULES_UNBLOCK,
        FRAUD_RULES_REMOVE
    } from "../../static/modals";

    // Modals
    import EmailSentModal from "./EmailSentModal.svelte";
    import DeleteUserModal from "./DeleteUserModal.svelte";
    import AddCustomerModal from "./AddCustomerModal.svelte";
    import FulfillOrder from "./FulfillOrder.svelte";
    import EditInvoice from "./EditInvoice.svelte";
    import RefundOrder from "./RefundOrder.svelte";
    import EditCreditLimitModal from "./EditCreditLimitModal.svelte";
    import ConfirmEditCreditModal from "./ConfirmEditCreditModal.svelte";
    import WelcomeToMerchantModal from "./WelcomeToMerchantModal.svelte";
    import AccountSetupModal from "./AccountSetup/AccountSetupModal.svelte";
    import ApiKeyModal from "./ApiKeyModal.svelte";
    import UserCreateUpdateMerchantModal from "./AddUserForMerchant/UserCreateUpdateMerchantModal.svelte";
    import UserDeleteMerchantModal from "./AddUserForMerchant/UserDeleteMerchantModal.svelte";
    import UserSuccessMessage from "./AddUserForMerchant/UserSuccessMessage.svelte";
    import ConfirmLogoutModal from "./ConfirmLogoutModal.svelte";
    import EditCustomerModal from "./EditCustomerModal.svelte";
    import OrderSubmitted from "./OrderSubmitted.svelte";
    import ConfirmCrossBorderOrder from "./MerchantInitiated/ConfirmCrossBorderOrder.svelte";
    import CancelOrderModal from "./CancelOrder.svelte";
    import CancelRemainingOrderModal from "./CancelRemainingOrder.svelte";
    import MiBuyerFallbackModal from "./MiBuyerFallbackModal.svelte";
    import ConfirmCustomerRecourseFallbackModal from "./ConfirmCustomerRecourseFallbackModal.svelte";
    import StatementOfAccountModal from "./StatementOfAccountModal.svelte";
    import SendBusinessRegistrationInvite from "./SendBusinessRegistrationInvite.svelte";
    import FraudRulesUnblock from "./FraudRulesUnblock.svelte";
    import FraudRulesRemove from "./FraudRulesRemove.svelte";

    let modal;
    let modalStatus = null;
    let props: any = null;
    let component: any = null;

    const closeModal = () => {
        if (props.closeModal) {
            props.closeModal();
        }
        modalState.actions.setModal(null, {}, null);
    };

    const handle_keydown = (e) => {
        if (modalStatus == null) return;
        if (e.key === "Escape") {
            close();
            return;
        }

        if (e.key === "Tab") {
            // trap focus
            const nodes = modal.querySelectorAll("*");
            const tabbable: any = Array.from(nodes).filter((n: any) => n.tabIndex >= 0);

            let index = tabbable.indexOf(document.activeElement);
            if (index === -1 && e.shiftKey) index = 0;

            index += tabbable.length + (e.shiftKey ? -1 : 1);
            index %= tabbable.length;

            tabbable[index].focus();
            e.preventDefault();
        }
    };

    const isCentered = () => {
        return ![CREATE_UPDATE_USER_MERCHANT].includes(modalStatus);
    };

    const previously_focused: any = typeof document !== "undefined" && document.activeElement;

    if (previously_focused) {
        onDestroy(() => {
            previously_focused.focus();
        });
    }

    $: modalState.modals((values) => {
        modalStatus = values.type;
        props = values.props;
        component = values.component;
    });

    function withXAuto(modalStatus) {
        if (
            ["INVOICE_STATEMENT_MODAL"].includes(modalStatus) ||
            props.id === "statement-of-account-modal"
        )
            return "";
        return "x-auto";
    }
</script>

<svelte:window on:keydown={handle_keydown} />

{#if modalStatus === EMAIL_SENT}
    <EmailSentModal Legal_name="" />
{/if}

{#if modalStatus === null || modalStatus === EMAIL_SENT}
    <div />
{:else}
    <div class="modal-background bg-black bg-opacity-70">
        <div
            class="w-content {isCentered() ? 'centered-modal' : 'right-bar'} {withXAuto(
                modalStatus
            )} z-120 bg-white shadow-xl"
            role="dialog"
            aria-modal="true"
            bind:this={modal}
        >
            {#if modalStatus === DELETE_USER}
                <DeleteUserModal />
            {:else if modalStatus === ADD_CUSTOMER_MODAL}
                <AddCustomerModal />
            {:else if modalStatus === SEND_BUSINESS_REGISTRATION_INVITE_MODAL}
                <SendBusinessRegistrationInvite />
            {:else if modalStatus === EDIT_INVOICE}
                <EditInvoice
                    invoiceID={props?.invoiceID}
                    billingAddress={props?.billingAddress}
                    merchantOrderReference={props?.merchantOrderReference}
                    purchaseOrderNumber={props?.purchaseOrderNumber}
                    project={props?.project}
                    orderNote={props?.orderNote}
                    lineItems={props?.lineItems}
                />
            {:else if modalStatus === FULFILL_ORDER}
                <FulfillOrder
                    orderID={props?.orderID}
                    merchantOrderID={props?.merchantOrderID}
                    lang={props?.lang}
                    buyerName={props?.buyerName}
                    isPartial={props?.isPartial}
                    lineItems={props?.lineItems}
                />
            {:else if modalStatus === EDIT_CREDITLIMIT}
                <EditCreditLimitModal {props} />
            {:else if modalStatus === CONFIRM_EDIT_CREDIT}
                <ConfirmEditCreditModal {props} />
            {:else if modalStatus === WELCOME_TILLIT}
                <WelcomeToMerchantModal />
            {:else if modalStatus === ACCOUNT_SETUP}
                <AccountSetupModal {props} />
            {:else if modalStatus === SANDBOX_API_KEY}
                <ApiKeyModal sandbox={true} />
            {:else if modalStatus === API_KEY}
                <ApiKeyModal />
            {:else if modalStatus === REFUND_ORDER}
                <RefundOrder
                    orderID={props?.orderID}
                    merchantOrderID={props?.merchantOrderID}
                    refundAmount={props?.refundAmount}
                    reason={props?.reason}
                    currency={props?.currency}
                    lang={props?.lang}
                    lineItems={props?.lineItems}
                    taxSubtotals={props?.taxSubtotals}
                />
            {:else if modalStatus === CREATE_UPDATE_USER_MERCHANT}
                <UserCreateUpdateMerchantModal {closeModal} {props} />
            {:else if modalStatus === DELETE_USER_MERCHANT}
                <UserDeleteMerchantModal {closeModal} {props} />
            {:else if modalStatus === CREATE_USER_MERCHANT_SUCCESS || modalStatus === UPDATE_USER_MERCHANT_SUCCESS || modalStatus === DELETE_USER_MERCHANT_SUCCESS}
                <UserSuccessMessage {closeModal} {props} {modalStatus} />
            {:else if modalStatus === CONFIRM_LOGOUT}
                <ConfirmLogoutModal {closeModal} {props} />
            {:else if modalStatus === EDIT_CUSTOMER}
                <EditCustomerModal {closeModal} {props} />
            {:else if modalStatus === CREATE_ORDER}
                <OrderSubmitted fName={props?.fName} companyName={props?.companyName} />
            {:else if modalStatus === CROSS_BORDER_ORDER}
                <ConfirmCrossBorderOrder />
            {:else if modalStatus === CANCEL_ORDER}
                <CancelOrderModal
                    orderID={props?.orderID}
                    merchantOrderID={props?.merchantOrderID}
                    isLatent={props?.isLatent}
                />
            {:else if modalStatus === CANCEL_REMAINING_ORDER}
                <CancelRemainingOrderModal
                    orderID={props?.orderID}
                    merchantOrderID={props?.merchantOrderID}
                    buyerCompanyName={props?.buyerCompanyName}
                />
            {:else if modalStatus === MI_BUYER_FALLBACK}
                <MiBuyerFallbackModal
                    newOrderIntent={props.newOrderIntent}
                    order_intent_data={props.order_intent_data}
                    data={props.data}
                    disableOrderBtn={props.disableOrderBtn}
                    buyerName={props.buyerName}
                />
            {:else if modalStatus === CONFIRM_ENABLE_CUSTOMER_RECOURSE_FALLBACK}
                <ConfirmCustomerRecourseFallbackModal {closeModal} {props} />
            {:else if modalStatus === "INVOICE_STATEMENT_MODAL"}
                <StatementOfAccountModal {closeModal} {props} />
            {:else if component}
                <!-- a more generic way to render the modalComponent -->
                <svelte:component this={component} {closeModal} {props} />
            {:else if modalStatus === FRAUD_RULES_UNBLOCK}
                <FraudRulesUnblock
                    valueType={props.valueType}
                    value={props.value}
                    companyName={props.companyName}
                />
            {:else if modalStatus === FRAUD_RULES_REMOVE}
                <FraudRulesRemove
                    valueType={props.valueType}
                    value={props.value}
                    companyName={props.companyName}
                />
            {/if}
        </div>
    </div>
{/if}

<style>
    .x-auto {
        overflow-x: auto;
    }

    .right-bar {
        right: 0;
        height: 100vh;
        position: fixed;
    }
    .z-120 {
        z-index: 120;
    }

    .centered-modal {
        position: relative;
        max-height: 90%;
        margin: 2rem auto;
        border-radius: 0.5rem;
    }
    .modal-background {
        position: fixed;
        z-index: 111;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        height: 100vh;
    }
</style>
