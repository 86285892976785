<script lang="ts">
    import { _ } from "svelte-i18n";

    import modalState from "../../../store/modals.store";
    import Button from "../../Elements/Button.svelte";
    import InputBox from "../../Elements/InputBox.svelte";
    import API from "../../../services/apiService";
    import notificationState from "../../../store/notifications.store";
    import { actions as accountActions } from "../../../store/merchant/account.store";
    import { getCountry, setCountry } from "../../../utils/auth";
    import { triggerHotjarEvent } from "../../../utils/methods";
    import { onDestroy, onMount } from "svelte";
    import PhoneInput from "../../../components/authentication/PhoneInput.svelte";
    import { type CountryCode, NotificationType } from "../../../static/types";
    import { COUNTRIES } from "@/static/constant";
    import { taxNaming } from "../../../store/merchant/account.store";
    import { GB, NO } from "../../../static/constant";
    import { account } from "../../../store/merchant/account.store";
    import { getCurrency } from "../../../utils/functions";
    import { emailIsValid, validatePhoneNumber } from "@/utils/validation";
    import { MERCHANT_SIGNUP } from "@/static/endPoints";
    import SignupCompleteModal from "./SignupCompleteModal.svelte";
    import environment from "@/services/environment";

    let step: number = 1;
    let uploadImage: HTMLInputElement = null;
    const countryCode: CountryCode = $account.countryCode;
    let phoneCountryCode: string = COUNTRIES[countryCode]?.phoneCountryCode ?? "";
    let invoiceEmailAddress: string = $account.emailInvoice || "";
    let phoneNumberWithCode: string = $account.phoneInvoice || phoneCountryCode + "";
    let phoneNumber: string = "";
    let vatNumber: string = $account.vat_number || "";
    let brandName: string = $account.accountName || "";
    let website: string = $account.website || "";
    let organizationName = $account.organizationName || "";
    let organizationNumber = $account.organizationNumber || "";
    let bankAccountNumber: string = $account.bban || "";
    let base64image: any;
    let logoPath: string = $account.logoPath || "";
    let imageName: string = "";
    let invalidVATNumber: string = "";
    let invalidInvoiceEmail: string = "";
    let invalidPhoneNumber: string = "";
    let invalidBankAccount: string = "";
    let invalidStreetAddress: string = "";
    let invalidPostalCode: string = "";
    let invalidCity: string = "";

    let loading: boolean = false;
    let isUploading: boolean = false;

    let address: any = {
        streetAddress: $account?.address?.streetAddress || "",
        city: $account?.address?.city || "",
        postalCode: $account?.address?.postalCode || ""
    };

    function onSubmit() {
        if (step == 1) {
            invalidInvoiceEmail = !invoiceEmailAddress
                ? $_("errors.enterEmail")
                : !emailIsValid(invoiceEmailAddress)
                  ? $_("errors.validEmail")
                  : "";

            invalidPhoneNumber = validatePhoneNumber($_, phoneCountryCode, phoneNumber, false);

            invalidVATNumber =
                countryCode !== GB || (countryCode === GB && vatNumber.length)
                    ? ""
                    : $_("errors.enterVATNumber");

            if (invalidInvoiceEmail || invalidPhoneNumber || invalidVATNumber) {
                return;
            }
        } else if (step === 2) {
            invalidBankAccount =
                countryCode !== NO || (countryCode === NO && bankAccountNumber.length)
                    ? ""
                    : $_("errors.enterBankAccountNumber");

            invalidStreetAddress = address.streetAddress.length
                ? ""
                : $_("errors.enterStreetAddress");
            invalidPostalCode = address.postalCode.length ? "" : $_("errors.enterPostalCode");
            invalidCity = address.city.length ? "" : $_("errors.enterCity");

            if (invalidBankAccount || invalidStreetAddress || invalidPostalCode || invalidCity) {
                return;
            }
        }

        if (step === 1 && (countryCode === NO || $account?.address?.streetAddress === "")) {
            step = 2;
        } else {
            if (
                invalidInvoiceEmail ||
                invalidPhoneNumber ||
                invalidVATNumber ||
                invalidBankAccount
            ) {
                return;
            }
            loading = true;
            const data: any = {
                submit: true,
                street_address: address.streetAddress,
                postal_code: address.postalCode,
                city: address.city,
                invoice_type: "DIRECT_INVOICE",
                region: $account.countryCode || getCountry(),
                bank_account: {
                    country_code: countryCode,
                    organization_name: organizationName,
                    organization_number: organizationNumber
                },
                email_invoice: invoiceEmailAddress,
                phone_number_invoice: phoneNumberWithCode,
                fee_currency: getCurrency(countryCode),
                fixed_fee_per_order: "0",
                percentage_fee_per_order: "0",
                due_in_days: $account.standardTerms || 30
            };

            if (base64image) {
                data.logo_base64 = base64image.split("base64,")[1];
                data.logo_file_name = imageName;
            }

            if (countryCode === GB && vatNumber.length) {
                data.vat_number = vatNumber;
            }

            if (countryCode === NO && bankAccountNumber.length) {
                data.bank_account = {
                    ...data.bank_account,
                    bban: bankAccountNumber
                };
            }

            if (brandName.length) {
                data.brand_name = brandName;
            }

            if (website.length) {
                data.website = website;
            }
            submitOnboardingData(data);
        }
    }

    const submitOnboardingData = (data: object) => {
        API.patch(MERCHANT_SIGNUP($account.application_id), data)
            .then(() => {
                accountActions.getAccount();
                modalState.actions.setModal("", {}, SignupCompleteModal);
                loading = false;
                setCountry("");
            })
            .catch((error: any) => {
                loading = false;

                let message: string = "error";
                if (error.response.data) {
                    const _error = error.response.data;
                    message = _error.error_details.length
                        ? _error.error_details
                        : _error.error_message;

                    if (
                        message === "NotAllowedExtensionError" ||
                        message === "Invalid base64 image"
                    ) {
                        message = $_("errors.logoImage");
                    } else {
                        message = $_("errors.accountWasNotCompleted");
                    }
                }
                loading = false;
                notificationState.actions.create(NotificationType.ERROR, message);
            });
    };

    function uploadImages() {
        uploadImage.click();
    }

    function getBase64(file: any) {
        if (file.size / 1024 / 1024 > 4) {
            notificationState.actions.create(NotificationType.ERROR, $_("errors.imageLessThan4MB"));
            return;
        }
        return new Promise((resolve) => {
            let baseURL: any = "";
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    }

    function onUploadImage(e: any) {
        const file = e.target.files[0];
        imageName = file.name;
        if (file.name.length > 40) {
            notificationState.actions.create(NotificationType.ERROR, $_("errors.imageNameTooLong"));
            return;
        }

        if (
            !file.name.toLowerCase().includes(".png") &&
            !file.name.toLowerCase().includes(".svg")
        ) {
            notificationState.actions.create(NotificationType.ERROR, $_("errors.imageSvgOrPng"));
            return;
        }

        isUploading = true;
        getBase64(file).then((result) => {
            file.base64 = result;
            base64image = result;
            isUploading = false;
        });
    }

    function removeImage() {
        base64image = "";
        imageName = "";
        logoPath = "";
    }

    const handleKeydown = (e) => {
        if (e.keyCode === 13 || e.code === "Enter") {
            onSubmit();
        }
    };
    onMount(() => {
        window.addEventListener("keydown", handleKeydown);
        triggerHotjarEvent("merchant-onboarding-company-identity");
    });
    onDestroy(() => {
        window.removeEventListener("keydown", handleKeydown);
    });
</script>

<div class="text-sm">
    {#if step === 1}
        <p class="header-text">{$_("onboarding.invoiceDetails")}</p>
        <p class="sub-header-text">{$_("onboarding.invoiceDetailsSubtitle")}</p>
        {#if countryCode === GB}
            <div class="flex w-full flex-wrap">
                <div class="w-full">
                    <p class="section-sub-header">
                        {$_("onboarding.vatNumber", { values: { taxNaming: $taxNaming } })}
                    </p>
                    <InputBox
                        type="text"
                        placeholder="GB123456789"
                        bind:value={vatNumber}
                        bind:invalid={invalidVATNumber}
                    />
                    {#if invalidVATNumber}
                        <p class="mb-10"></p>
                    {/if}
                </div>
            </div>
        {/if}
        <p class="section-sub-header">{$_("onboarding.detailsOnInvoice")}</p>
        <InputBox
            type="text"
            bind:value={invoiceEmailAddress}
            bind:invalid={invalidInvoiceEmail}
            placeholder={$_("onboarding.emailPlaceholder")}
        />
        {#if invalidInvoiceEmail}
            <p class="mb-10"></p>
        {/if}
        <div class="mt-4">
            <PhoneInput
                disable={false}
                bind:phoneNumberWithCode
                bind:phoneNumber
                bind:countryCode={phoneCountryCode}
                bind:invalid={invalidPhoneNumber}
            />
            {#if invalidPhoneNumber.length}
                <p class="error">{invalidPhoneNumber}</p>
            {/if}
        </div>
        <p class="section-sub-header">{$_("onboarding.brandNamePlaceholder")}</p>
        <InputBox
            type="text"
            bind:value={brandName}
            placeholder={$_("onboarding.brandNamePlaceholder")}
        />
        <div class="mt-4">
            <InputBox type="text" placeholder="Website address" bind:value={website} />
        </div>
        <div class="flex items-center image_upload p-6 rounded-lg mt-4 text-xs">
            {#if base64image || logoPath}
                <div class="flex-1 flex items-center justify-between pr-1">
                    <div class="flex items-center">
                        <img class="h-8 round-sm" src={`${base64image || logoPath}`} alt="logo" />
                        <p class="ml-2 break-all overflow-hidden">{imageName}</p>
                    </div>
                    <button on:click={removeImage}>
                        <img src="/pictures/close.svg" alt="close" />
                    </button>
                </div>
            {:else}
                <p class="pr-1">
                    {@html $_("onboarding.uploadLogoInstruction")}
                </p>
            {/if}
            <div class="ml-4 relative w-28 flex-shrink-0">
                <Button size="small" label="Browse" loading={isUploading} on:click={uploadImages} />
                <input
                    bind:this={uploadImage}
                    on:change={onUploadImage}
                    type="file"
                    accept="image/*"
                />
            </div>
        </div>
    {/if}
    {#if step === 2}
        <p class="header-text">{$_("onboarding.payoutDetails")}</p>
        <p class="sub-header-text">
            {$_("onboarding.payoutDetailsSubtitle", {
                values: { brandName: environment.branding.displayName }
            })}
        </p>
        {#if countryCode === NO}
            <p class="section-sub-header">{$_("onboarding.bankAccountNumberPlaceholder")}</p>
            <InputBox
                type="text"
                bind:value={bankAccountNumber}
                bind:invalid={invalidBankAccount}
                placeholder="15038148353"
            />
            {#if invalidBankAccount}
                <p class="mb-10"></p>
            {/if}
        {/if}
        {#if $account?.address?.streetAddress === ""}
            <p class="section-sub-header">{$_("onboarding.addressDetails")}</p>
            <InputBox
                type="text"
                bind:value={address.streetAddress}
                bind:invalid={invalidStreetAddress}
                placeholder={$_("onboarding.streetAddressPlaceholder")}
            />
            {#if invalidStreetAddress}
                <p class="mb-10"></p>
            {/if}
            <div class="address-details">
                <div>
                    <InputBox
                        type="text"
                        bind:value={address.postalCode}
                        bind:invalid={invalidPostalCode}
                        placeholder={$_("onboarding.postalCodePlaceholder")}
                    />
                    {#if invalidPostalCode}
                        <p class="mb-10"></p>
                    {/if}
                </div>
                <div>
                    <InputBox
                        type="text"
                        bind:value={address.city}
                        bind:invalid={invalidCity}
                        placeholder={$_("onboarding.cityPlaceholder")}
                    />
                    {#if invalidCity}
                        <p class="mb-10"></p>
                    {/if}
                </div>
            </div>
        {/if}
    {/if}
    <div class="mt-6">
        <Button
            size="xlarge"
            label={step === 1 && (countryCode === NO || $account?.address?.streetAddress === "")
                ? $_("onboarding.continue")
                : $_("onboarding.complete")}
            {loading}
            on:click={onSubmit}
        />
    </div>
    {#if step === 2}
        <div class="mt-4">
            <Button
                color={"var(--gray-700)"}
                hoverBg={"white"}
                hoverColor={"black"}
                bgColor={"white"}
                borderColor={"white"}
                size="xlarge"
                label={$_("components.back")}
                on:click={() => {
                    step = 1;
                }}
            />
        </div>
    {/if}
</div>

<style>
    .header-text {
        color: var(--bg-active);
        text-align: center;

        font-family: Aeonik;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }

    .sub-header-text {
        color: var(--gray-500);
        text-align: center;
        margin-top: 1rem;

        font-family: Aeonik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .section-sub-header {
        color: var(--gray-500);
        margin-top: 1.5rem;

        font-family: Aeonik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .image_upload {
        border: 1px solid #dce0e6;
    }

    input[type="file"] {
        opacity: 0;
        position: absolute;
    }

    .address-details {
        display: grid;
        margin: 1.5rem 0 1rem 0;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }

    .error {
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
</style>
