<script lang="ts">
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import Fa from "svelte-fa";
    import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

    import Icon from "../icons/Icon.svelte";
    import Button from "../../components/Elements/Button.svelte";
    import type { ISelectorLabelType } from "./../../static/types";

    export let size: string = "48px";
    export let options: any[] = ["asdf", "qwer", "zxcv"];
    export let value: string = "";
    export let label: string = "";
    export let description: string = "";
    export let invalid: string = "";
    export let showInvalidError: boolean = true;
    export let placeholder: string = "Select items...";
    export let required: boolean = false;
    export let requiredStar: boolean = true;
    export let backgroundColor = "";
    export let id = "";
    export let labelType: ISelectorLabelType = "DEFAULT";
    export let showArrow = false;
    export let customStyles = "";

    let isFocus: boolean = false;
    let dropDown: HTMLElement;
    let isOpen: boolean = false;
    let selectWrapper: HTMLDivElement;
    let dropDownList: HTMLElement;

    $: if (dropDownList) dropUp();

    function dropUp() {
        if (dropDownList.getBoundingClientRect().bottom > window.innerHeight) {
            dropDownList.classList.add("drop-up");
        }
    }

    let selectedItemIdx = -1;

    function topLabel(): any {
        isOpen = !isOpen;
        isFocus = true;
    }

    function closeDown(event): any {
        if (!selectWrapper.contains(event.target) && !dropDown.contains(event.target)) {
            isOpen = false;
        }
        if (!isOpen) isFocus = false;
    }

    const dispatch = createEventDispatcher();

    function selectOption(option, isClicked) {
        selectedItemIdx = -1;
        isOpen = labelType == "BUTTON" && !isClicked ? true : false;
        value = option;
        dispatch("change", { value, id });
    }

    function handleMouseOver(item) {
        dispatch("hoverItem", {
            item
        });
    }

    function selectItem(event): any {
        if (isOpen && labelType == "BUTTON") {
            if (event.key === "ArrowDown" && selectedItemIdx < options.length - 1) {
                selectedItemIdx++;
            }
            if (event.key === "ArrowUp" && selectedItemIdx > 0) {
                selectedItemIdx--;
            }
            if (event.key === "Enter" && selectedItemIdx > -1) {
                selectOption(options[selectedItemIdx], false);
            }
        }
    }

    onMount(() => {
        window.addEventListener("click", closeDown);
        document.addEventListener("keydown", selectItem);
    });

    onDestroy(() => {
        window.removeEventListener("click", closeDown);
        document.removeEventListener("keydown", selectItem);
    });

    $: {
        if (value?.length > 0) invalid = "";
    }
</script>

<div {id} class="text-xs" style="--height: {size}">
    {#if label !== ""}
        <div class="mb-[6px]">
            <label
                class="cursor-pointer text-gray-700 text-sm font-medium"
                class:focus={isFocus}
                class:error={invalid}
                for="selector"
            >
                {label}
                {#if required && requiredStar}
                    <span class="star">*</span>
                {/if}
            </label>
        </div>
    {/if}
    <div class="relative text-sm">
        {#if showArrow}
            <Fa
                icon={faChevronDown}
                size="lg"
                color="var(--borderGeneralSecondary)"
                class="absolute"
                style="top:50%; transform: translateY(-50%); right: 10px"
            />
        {/if}
        <div
            class="selector flex"
            class:flex-col={labelType == "BUTTON"}
            class:items-end={labelType == "BUTTON"}
            class:justify-center={labelType == "BUTTON"}
            class:justify-between={labelType == "DEFAULT"}
            class:items-center={labelType == "DEFAULT"}
            style={`background-color: ${backgroundColor};${customStyles}`}
            id="selector"
            on:click={topLabel}
            on:keypress={topLabel}
            bind:this={dropDown}
            class:border_focus={isFocus}
            class:invalid
        >
            {#if labelType == "BUTTON"}
                <div class="selector-button">
                    <Button
                        size="medium"
                        label={value || placeholder}
                        color={"inherit"}
                        hoverColor={"inherit"}
                        bgColor={"transparent"}
                        hoverBg={"transparent"}
                        borderColor={"transparent"}
                        classes="selector-button-content whitespace-nowrap {value?.length > 0
                            ? 'selector-value'
                            : 'selector-placeholder'}"
                    />

                    <div class="mr-4 ml-2 z-10">
                        <div class={`${isOpen && "rotate"} dropdown_icon`}>
                            <Icon name="drop-arrow-down" fill="var(--ctnGeneralSecondary)" />
                        </div>
                    </div>
                </div>
            {:else}
                <p
                    class="text-ls pl-4 selector-default {value?.length > 0
                        ? 'selector-value'
                        : 'selector-placeholder'}"
                >
                    {@html value || placeholder}
                </p>
            {/if}
        </div>
        {#if showInvalidError}
            <p class="text-xs font-medium error w-max">
                {invalid}
            </p>
        {/if}
        <div class="relative w-full" bind:this={selectWrapper}>
            {#if isOpen}
                <div class="w-full dropdown-list items" bind:this={dropDownList}>
                    {#if description}
                        <div class="item_des flex items-center cursor-pointer pl-4 font-bold">
                            {description}
                        </div>
                    {/if}
                    {#each options as option, i}
                        <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                        <div
                            class="dropdown-item item flex items-center cursor-pointer pl-4"
                            class:bg-gray-200={selectedItemIdx === i}
                            class:border-none={options.length === i + 1}
                            on:click|stopPropagation={() => selectOption(option, true)}
                            on:keypress={() => selectOption(option, true)}
                            on:mouseover={() => handleMouseOver(option)}
                        >
                            {@html option}
                        </div>
                    {/each}
                </div>
            {/if}
        </div>
    </div>
</div>

<style>
    .selector {
        background-color: #f9fafb;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        border: 1px solid var(--gray-300);
        height: var(--height);
    }
    .selector:has(.selector-button) {
        overflow: hidden;
    }
    .focus {
        color: var(--ctnGeneralPrimary);
    }
    .items {
        position: absolute;
        background-color: white;
        z-index: 20;
        border: 1px solid var(--gray-100);
        border-radius: 8px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 250px;
        box-shadow: 0px 5px 30px rgba(64, 58, 85, 0.1);
    }
    .item,
    .item_des {
        height: var(--height);
        border-bottom: 1px solid #ebeaef;
    }
    .border_focus {
        border: 2px solid var(--borderSemanticHighlightSecondary);
        background: white;
    }
    .invalid {
        border: 1px solid var(--semantic-error);
        background-color: var(--semantic-error-light);
    }
    .error {
        top: 48px;
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .rotate {
        transform: rotate(180deg);
    }
    .dropdown_icon {
        transition: 0.3s;
    }
    .drop-up {
        bottom: 48px;
    }
    .border-none {
        border-bottom: none !important;
    }

    .selector-button {
        height: 40px;
        border-radius: 10px;
        z-index: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 16px;
    }

    .selector-value {
        font-size: 16px;
    }

    .selector-placeholder {
        color: var(--gray-500);
        opacity: 0.75;
        font-size: 16px;
    }
</style>
