<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import type {
        CurrencyCode,
        IAddress,
        ICountry,
        IMerchantRecourseLimit,
        IMerchantRemainingRecourseLimit
    } from "@/static/types";
    import { onMount, onDestroy } from "svelte";

    import PhoneInput from "../../../../components/authentication/PhoneInput.svelte";
    import CompanySelector from "../../../../components/companySelector/CompanySelector.svelte";
    import CountrySelector from "../../../../components/countrySelector/CountrySelector.svelte";
    import Selector from "../../../../components/Elements/Selector.svelte";
    import { TextInput } from "@two-ui/core";
    import ToolTip from "../../../../components/tooltip/ToolTip.svelte";
    import Icon from "../../../../components/icons/Icon.svelte";
    import type countryName from "../../../../static/countryName";
    import { account, recourseLimitConfig } from "../../../../store/merchant/account.store";
    import {
        actions,
        creditLimitState,
        creditLimitLoading
    } from "../../../../store/merchant/creditlimit.store";
    import {
        actions as customerStateActions,
        buyerRecourseLimit,
        buyerRecourseLimitLoading
    } from "../../../../store/merchant/customers.store";
    import modalState from "../../../../store/modals.store";
    import orderCreateState from "../../../../store/merchant/orderCreate.store";
    import utils, { type CurrencyFormatOptions } from "../../../../utilsV2/currency";
    import { COUNTRIES } from "../../../..//static/constant";
    import BuyerCountryExpansionModal from "../../../../components/modals/BuyerCountryExpansionModal.svelte";
    import { uniqueId } from "lodash";
    import { getBuyerCountries } from "../../../../static/customers";
    import environment from "@/services/environment";
    import { buyerDetails, orderReplica } from "@/store/merchant/allOrders.store";

    export let companySearchText: string = "";
    export let company: string = "";
    export let companyID: string = "";
    export let companyExternalID = "";
    export let availableCreditLimit: number;
    export let maxOrderValue: number;
    export let buyerFallbackEnabled: boolean;
    export let fallbackThreshold: string;
    export let merchantRecourseLimitValue: number;
    export let buyerRecourseLimitValue: number;
    export let currency: CurrencyCode;

    let countryCode: keyof typeof countryName;
    const zipCode = "";
    let customerCountry: any = {};
    let showCreditLimit: boolean = false;

    let merchantRecourseLimit: IMerchantRecourseLimit;
    let merchantRemainingRecourseLimits: IMerchantRemainingRecourseLimit[];
    let availableCountries = {};
    let countryPickerKey = uniqueId();

    function getCreditLimit(address: IAddress) {
        company = companySearchText;
        actions.resetState();
        if (
            !$creditLimitLoading &&
            companySearchText &&
            companyID &&
            countryCode &&
            (address?.postalCode || address?.streetAddress || address?.city)
        ) {
            // credit limit is hidden while loading
            actions.getCreditLimit(companyID, countryCode, company, companyExternalID, currency);
            customerStateActions.getCustomerRecourseLimit(companyID, countryCode, currency);
        } else {
            // if no valid company and address set, credit limit should be hidden
            showCreditLimit = false;
        }
    }

    $: if ($recourseLimitConfig) {
        // only if the recourseLimitConfig state is loaded
        merchantRecourseLimit = $recourseLimitConfig?.merchant_recourse_limits?.[0];
        merchantRemainingRecourseLimits = $recourseLimitConfig?.merchant_remaining_recourse_limit;
    }

    $: merchantRecourseLimitValue = merchantRecourseLimit?.recourse_fallback_enabled
        ? parseFloat(
              (
                  merchantRemainingRecourseLimits.filter(
                      (x) => x.currency == merchantRecourseLimit?.currency
                  )[0]?.remaining_recourse_limit || 0
              ).toString()
          )
        : 0;

    const formatMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(currency)(money, options);

    $: showCreditLimit =
        $creditLimitState?.success &&
        companyID &&
        ![null, undefined].includes(availableCreditLimit) &&
        ![null, undefined].includes(maxOrderValue) &&
        availableCreditLimit >= 0 &&
        maxOrderValue >= 0;

    $: getCreditLimit(address);

    $: if (!$creditLimitLoading && $creditLimitState?.success) {
        availableCreditLimit = $creditLimitState.creditLimit.availableCreditLimit;
        maxOrderValue = $creditLimitState.creditLimit.maxSingleOrderValue;
        buyerFallbackEnabled = $creditLimitState.creditLimit.buyerFallbackEnabled;
        fallbackThreshold = $creditLimitState.creditLimit.fallbackThreshold;
    }

    $: if (customerCountry == undefined) {
        modalState.actions.setModal("", {}, BuyerCountryExpansionModal);
        customerCountry = {};
        countryPickerKey = uniqueId();
    }

    $: if (customerCountry?.country_code) {
        countryCode = customerCountry.country_code;
        orderCreateState.actions.setCountry(customerCountry);
        countryUpdated();
    }

    $: orderCreateState.country.subscribe((country: ICountry) => {
        if (country) {
            currency = country.currency;
            countryCode = country.country_code;
        }
    });

    export let address: any = {};
    export let representative: {
        first_name: string;
        last_name: string;
        phone_number: string;
        email: string;
    } = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: ""
    };
    export let invalid = {
        selectCompany: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        orderEmail: ""
    };

    export let invoiceType: string = "FUNDED_INVOICE";

    let phoneNumberWithCode: string = "+44";
    let phoneNumber: string = "";
    let isCrossBorderEnabled: boolean = false;
    let phoneInput;

    $: if (phoneNumberWithCode) {
        phoneNumberChanged();
    }
    // Subscribe to merchant account data
    $: if (
        $account?.buyer_country_configurations?.length > 1 &&
        $account.flags.cross_border_enabled
    ) {
        isCrossBorderEnabled = true;
        availableCountries = getBuyerCountries($account.buyer_country_configurations);
    }

    $: if ($account?.countryCode) {
        phoneInput?.setCountry($account.countryCode);
    }

    onMount(() => {
        document.getElementsByClassName("iti__selected-flag")[0].setAttribute("tabindex", "-1");
        // Apply replicated order details
        if ($orderReplica) {
            applyReplicatedOrder();
        } else if ($buyerDetails) {
            applyBuyerDetails();
        }
    });

    onDestroy(() => {
        buyerDetails.set(null);
    });

    function applyReplicatedOrder() {
        companySearchText = $orderReplica.companyName;
        // fill user representative details
        const [firstName, lastName] = $orderReplica.buyerName.split(" ");
        representative.first_name = firstName;
        representative.last_name = lastName;
        phoneNumberWithCode = $orderReplica.phone;
        representative.email = $orderReplica.email;
    }

    function applyBuyerDetails() {
        companySearchText = $buyerDetails.companyName;
    }

    function countryUpdated() {
        if (!$orderReplica) {
            phoneInput?.setCountry(countryCode);
            phoneNumberWithCode = COUNTRIES[countryCode]?.phoneCountryCode ?? "" + phoneNumber;
        }
        if (countryCode !== "NO" && $account.countryCode === "NO") {
            invoiceType = "DIRECT_INVOICE";
            modalState.actions.setModal("CROSS_BORDER_ORDER", {});
        }
    }

    function onCurrencyChange() {
        if (companyID) {
            getCreditLimit(address);
        }
    }

    function phoneNumberChanged() {
        representative.phone_number = phoneNumberWithCode;
    }
</script>

<div>
    <p class="text-lg ctn-general-primary font-semibold mr-3 mt-2">
        {$_("buyerDetails.customerDetails")}
    </p>
    <div>
        <div class="company-search-container">
            {#if isCrossBorderEnabled}
                {#key countryPickerKey}
                    <div class="text-sm" style="grid-column: span 1;">
                        <CountrySelector
                            bind:country={customerCountry}
                            countries={availableCountries}
                            size="42px"
                            initialCountryCode={$orderReplica?.country
                                ? $orderReplica?.country
                                : $buyerDetails?.countryCode
                                  ? $buyerDetails?.countryCode
                                  : $account.countryCode}
                            on:change={() => {
                                companySearchText = "";
                                company = "";
                            }}
                            notOnListText={$_("buyerDetails.getMoreCountries")}
                            notOnListTextColor="var(--primary-500)"
                            showNotOnList={true}
                        />
                    </div>
                {/key}
                {#if customerCountry?.buyerCurrencies?.length > 1}
                    {#key customerCountry}
                        <div style="grid-column:span 1;">
                            <Selector
                                size="42px"
                                label={$_("buyerDetails.currency")}
                                options={customerCountry.buyerCurrencies}
                                bind:value={currency}
                                placeholder={currency}
                                showArrow={true}
                                on:change={onCurrencyChange}
                            />
                        </div>
                    {/key}
                {/if}
            {/if}
            <div class="relative search-container">
                <div class="absolute search-icon">
                    <Icon size={24} name="search" fill="#9ca3af" />
                </div>
                <CompanySelector
                    {countryCode}
                    showId={false}
                    bind:address
                    bind:company={companySearchText}
                    bind:companyID
                    bind:companyExternalID
                    {zipCode}
                    invalid={invalid.selectCompany}
                    placeholderText={$_("buyerDetails.enterCompanyName")}
                />
            </div>
        </div>
        {#if showCreditLimit && !$creditLimitLoading && !$buyerRecourseLimitLoading}
            <div class="flex flex-col mt-6 rounded p-6 credit-details">
                <div>
                    <p class="text-2xl ctn-general-primary font-semibold">{company}</p>
                    <p class="text-sm ctn-general-tertiary">
                        {$_("buyerDetails.orgNumber")}
                        <span class="font-semibold ctn-general-secondary">{companyID}</span>
                    </p>
                    <p class="text-sm ctn-general-tertiary">
                        {$_("buyerDetails.registeredAddress")}
                        <span class="font-semibold">
                            {address.streetAddress}, {address.city}, {address.postalCode}
                        </span>
                    </p>
                    <hr class="mt-4 mb-4 separator" />
                </div>
                <div class="flex flex-row justify-between">
                    <div class="flex flex-col w-1/2">
                        <p class="text-base ctn-general-primary font-semibold">
                            {$_("buyerDetails.availableCreditLimit")}
                        </p>
                        {#if buyerRecourseLimitValue && buyerRecourseLimitValue > 0}
                            <p class="text-sm ctn-general-tertiary">
                                {$_("buyerDetails.notApplicableToOrders")}
                            </p>
                        {/if}
                        <div class="flex flex-row w-full mt-3">
                            <div class="flex flex-col w-1/2">
                                <ToolTip
                                    width="250px"
                                    position="top"
                                    content={$_("buyerDetails.tooltip.availableCreditLimit", {
                                        values: { brandName: environment?.branding.displayName }
                                    })}
                                >
                                    <div class="flex text-sm items-center ctn-general-tertiary">
                                        {$_("buyerDetails.remainingCreditLimit")} &nbsp;
                                        <Fa
                                            size="md"
                                            icon={faCircleQuestion}
                                            color="var(--primary-500)"
                                        />
                                    </div>
                                </ToolTip>
                                <p class="text-xl ctn-general-primary font-semibold">
                                    {formatMoney(Math.floor(availableCreditLimit))}
                                </p>
                            </div>
                            <div class="flex flex-col w-1/2">
                                <ToolTip
                                    width="250px"
                                    position="top"
                                    content={$_("buyerDetails.tooltip.maxOrderValue", {
                                        values: { brandName: environment?.branding.displayName }
                                    })}
                                >
                                    <div class="flex text-sm items-center ctn-general-tertiary">
                                        {$_("buyerDetails.maxOrderValue")} &nbsp;
                                        <Fa
                                            size="md"
                                            icon={faCircleQuestion}
                                            color="var(--primary-500)"
                                        />
                                    </div>
                                </ToolTip>
                                <p class="text-xl ctn-general-primary font-semibold">
                                    {formatMoney(Math.floor(maxOrderValue))}
                                </p>
                            </div>
                        </div>
                    </div>

                    {#if merchantRecourseLimit?.recourse_fallback_enabled}
                        <div class="flex flex-col w-1/2">
                            <p class="text-base ctn-general-primary font-semibold">
                                {$_("buyerDetails.availableRecourseFallbackLimit")}
                            </p>
                            {#if buyerRecourseLimitValue && buyerRecourseLimitValue > 0}
                                <p class="text-sm ctn-general-tertiary">
                                    {$_("buyerDetails.allowAcceptingOrders")}
                                </p>
                            {/if}
                            <div class="flex flex-row w-full mt-3">
                                <div
                                    class="flex flex-col w-1/2"
                                    class:disabled={!$buyerRecourseLimit ||
                                        buyerRecourseLimitValue == 0}
                                >
                                    <ToolTip
                                        width="250px"
                                        position="top"
                                        content={$_("buyerDetails.tooltip.customerRecourseLimit")}
                                    >
                                        <div class="flex text-sm items-center ctn-general-tertiary">
                                            {$_("buyerDetails.forThisCustomer")} &nbsp;
                                            <Fa
                                                size="md"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </div>
                                    </ToolTip>
                                    <p class="text-xl ctn-general-primary font-semibold">
                                        {formatMoney(Math.floor(buyerRecourseLimitValue) || 0)}
                                    </p>
                                    {#if !$buyerRecourseLimit || buyerRecourseLimitValue == 0}
                                        <p class="text-xs text-gray">
                                            {$_("buyerDetails.recourseFallbackDisabled")}
                                        </p>
                                    {/if}
                                </div>
                                <div class="flex flex-col w-1/2">
                                    <ToolTip
                                        width="250px"
                                        position="top"
                                        content={$_("buyerDetails.tooltip.merchantRecourseLimit", {
                                            values: { brandName: environment?.branding.displayName }
                                        })}
                                    >
                                        <div class="flex text-sm items-center ctn-general-tertiary">
                                            {$_("buyerDetails.acrossAllCustomers")} &nbsp;
                                            <Fa
                                                size="md"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </div>
                                    </ToolTip>
                                    <p class="text-xl ctn-general-primary font-semibold">
                                        {formatMoney(Math.floor(merchantRecourseLimitValue))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
        <div class="mt-6 details-container">
            <p>{$_("buyerDetails.representativeDetails")}</p>
            <div class="flex flex-container">
                <div class="w-full mt-5">
                    <TextInput
                        label={$_("buyerDetails.firstName")}
                        id="customer_first_name"
                        size="md"
                        placeholder={$_("buyerDetails.enterFirstName")}
                        bind:value={representative.first_name}
                        bind:error={invalid.first_name}
                        required={true}
                    />
                </div>
                <div class="w-full mt-5">
                    <TextInput
                        label={$_("buyerDetails.lastName")}
                        id="customer_last_name"
                        size="md"
                        placeholder={$_("buyerDetails.enterLastName")}
                        bind:value={representative.last_name}
                        bind:error={invalid.last_name}
                        required={true}
                    />
                </div>
            </div>
            <div class="flex pt-4 mb-4 flex-container">
                <div class="w-full mt-3">
                    <div class="flex items-center mb-1">
                        <label
                            class="label text-sm text-gray-700"
                            class:invalid={invalid.phone_number}
                            for=""
                        >
                            {$_("buyerDetails.phoneNumber")}
                            <span class="text-main" class:invalid={invalid.phone_number}>*</span>
                        </label>
                    </div>
                    <div class="phone_number_container">
                        <PhoneInput
                            disable={false}
                            bind:phoneNumberWithCode
                            bind:phoneNumber
                            bind:this={phoneInput}
                            invalid={invalid.phone_number}
                            on:input={() => {
                                invalid.phone_number = "";
                            }}
                        />
                    </div>
                    {#if invalid.phone_number}
                        <p class="mt-1 text-sm invalid">{invalid.phone_number}</p>
                    {/if}
                </div>
                <div class="w-full mt-3">
                    <TextInput
                        label={$_("buyerDetails.email")}
                        id="customer_email"
                        size="md"
                        placeholder={$_("buyerDetails.enterEmail")}
                        bind:value={representative.email}
                        bind:error={invalid.email}
                        required={true}
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .flex-container {
        gap: 1rem;
    }

    * :global(#company) {
        font-size: 16px;
    }

    * :global(.search-container .input_wrapper > input) {
        padding-left: 3rem;
    }

    .search-icon {
        bottom: 0.6rem;
        left: 1rem;
        z-index: 10;
    }

    .invalid {
        color: var(--error-600);
    }

    .disabled .text-xs,
    .disabled .text-sm,
    .disabled .text-xl {
        opacity: 0.7;
    }

    .company-search-container {
        width: 100%;
        display: grid;
        gap: 1rem;
        justify-content: space-between;
        align-items: flex-end;
        grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    }

    .search-container {
        grid-column: span 2;
    }

    .separator {
        border-color: #a4bcfd;
    }

    .credit-details {
        background-color: var(--bgSemanticHighlightTertiary);
    }
</style>
